<template>
  <div id="phoneBook">

    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Imenik zdravstvenih ustanov</li>
    </vs-breadcrumb>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6" class="mb-10">
        <vs-input size="large" class="w-full" placeholder="Išči" icon="search" type="text"
                  v-model="search" @input="searchPhoneBook"/>
      </vs-col>
    </vs-row>


    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Vpiši novo donacijo" @click.stop="addPhoneBookEntry()"></vs-button>


    <div class="grid grid-cols-3 gap-10">

      <vs-card v-for="(item, index) in filteredPhoneBookData" :key="'phoneBookItem_' + index"
               style="position: relative; padding-bottom: 20px">
        <div slot="header">
          <h4>
            {{ item.name }}
          </h4>
        </div>
        <div>
          <vs-list>
            <span class="generalDataContainer">
              <vs-list-header title="Splošni podatki"></vs-list-header>
              <span @click.stop.prevent="openAddress(item.address.quick_search)" style="cursor: pointer;">
                <vs-list-item title="Naslov" :subtitle="item.address.quick_search"></vs-list-item>
              </span>
              <vs-divider class="my-0"></vs-divider>
              <span @click.stop.prevent="openWebsite(item.website)" style="cursor: pointer;">
                <vs-list-item title="Spletno mesto" :subtitle="item.website"></vs-list-item>
              </span>
            </span>
            <span class="phoneNumbersContainer">
              <vs-list-header title="Telefonske številke" color="success" class="mt-10"></vs-list-header>
              <vs-list-item v-for="(phoneItem, _index) in JSON.parse(item.phones)" :title="phoneItem.note"
                            :key="'phoneItem_' + _index">
                <a :href="'tel://' + phoneItem.phone">{{ phoneItem.phone }}</a>
              </vs-list-item>
            </span>
          </vs-list>
        </div>
        <vs-button color="primary" icon="edit" style="position: absolute; right: 10px; bottom: 10px;" @click="editPhoneBookItem(item.id)"></vs-button>
      </vs-card>

    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import PrivateDonationsTable from "../../components/Sopotniki/donations/PrivateDonationsTable";
import {slSI} from "vuejs-datepicker/dist/locale";
import sir from "@/assets/images/portrait/sir.svg";
import madam from "@/assets/images/portrait/madam.svg";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";


export default {
  name: 'PhoneBook',

  components: {
    'v-select': vSelect,
    Datepicker,
    PrivateDonationsTable
  },

  data() {
    return {
      filteredPhoneBookData: null,
      phoneBookData: null,
      search: null
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.$vs.loading();
    _this.getPhoneBook();
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    async getPhoneBook() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'v2/contacts')
        .then((res) => {
          _this.phoneBookData = res.data.data;
          _this.filteredPhoneBookData = res.data.data;
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    addPhoneBookEntry() {
      const _this = this;

      _this.$router.push({name: 'PhoneBookEdit'})
    },

    openWebsite(website) {
      console.log("website", website);
      window.open(website);
    },

    openAddress(address) {
      console.log("address");
      window.open('https://www.google.com/maps/search/?api=1&query=' + address.split(' ').join('+'))
    },

    // filterData(query) {
    //   const _this = this;
    //
    //   _this.documents = _.filter(_this.documentsOriginal, function (_item) {
    //     return JSON.stringify(_item).indexOf(query) > -1;
    //   });
    // },

    searchPhoneBook() {
      const _this = this;

      _this.filteredPhoneBookData = _.filter(_this.phoneBookData, function (_item) {
        return JSON.stringify(_item).toLowerCase().includes(_this.search.toLowerCase());
      });
    },

    editPhoneBookItem(id) {
      const _this = this;

      _this.$router.push({name: 'PhoneBookEdit', params: {phoneBookId: id}})
    }
  }
}
</script>

